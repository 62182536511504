<script setup lang="ts">
import Button from 'primevue/button'
import type { ProductsRequestQuery } from 'gig/types/products-request-query'
import IconClose from '@/assets/images/close.svg'
import { categoryFilters } from '@/data/category/category-filters'
const props = defineProps<{
  filters: ProductsRequestQuery[]
}>()
defineEmits<{
  (e: 'remove', id: string, optionValue: string | null): void
  (e: 'removeAll'): void
}>()
const listingStore = useListingStore()

const getNames = (id: string) => {
  const categoryFilter = categoryFilters.find((filter) => filter.id === id)
  return {
    name:
      categoryFilter?.activeFilterName !== undefined
        ? categoryFilter.activeFilterName
        : categoryFilter?.name,
    defaultName: categoryFilter?.name,
  }
}

const { $formatToCompactNumber } = useNuxtApp()
const convertStringToRange = (range: string) => {
  if (range.includes('range')) {
    const [minValue = 0] = range.match(/\d+|\*/g) || []
    return `${$formatToCompactNumber(+minValue)}+`
  } else {
    const [minValue = 0, maxValue = 0] = range.match(/\d+|\*/g) || []
    const formattedMinValue =
      minValue === '*' ? '*' : `$${Math.floor(+minValue / 100)}`
    const formattedMaxValue =
      maxValue === '*' ? '*' : `$${Math.floor(+maxValue / 100)}`
    return `${formattedMinValue} to ${formattedMaxValue}`
  }
}
const getValue = (filter: ProductsRequestQuery['value']) => {
  const categoryFilter = categoryFilters.find((el) => el.id === filter.id)
  if (categoryFilter?.isNumericRange) {
    return convertStringToRange(filter.value[0])
  }
  return filter.value.join(', ')
}

const activeFilters = computed(() => {
  return props.filters.flatMap((filter) => {
    const { id } = filter.value
    const names = getNames(id)
    const filterValue = getValue(filter.value)
    const categoryFilter = categoryFilters.find((el) => el.id === id)
    let customValue: string | undefined = ''
    if (categoryFilter?.splitActiveOptions) {
      return filterValue.split(', ').map((val) => ({
        id,
        optionId: `${id}-${val}`,
        customValue,
        value: val,
        ...names,
      }))
    } else if (id === 'variants.attributes.delivery_time' && categoryFilter) {
      const deliveryValues = filterValue.split(', ')
      const mapping = categoryFilter.mapping as {
        [key: string]: string[]
      }
      customValue = Object.keys(mapping).find((key) =>
        mapping[key].every((value, index) => value === deliveryValues[index]),
      )
    } else if (id === 'variants.attributes.location') {
      customValue = getCountryName(filterValue)
    }
    return {
      id,
      optionId: '',
      customValue,
      value: id !== 'variants.attributes.content_type' ? filterValue : '',
      ...names,
    }
  })
})
</script>

<template>
  <ul
    v-if="activeFilters?.length"
    class="mb-4 flex flex-wrap gap-x-4 gap-y-2"
  >
    <li>
      <Button
        severity="danger"
        outlined
        label="Clear Filters"
        class="p-2.5"
        @click="$emit('removeAll')"
      />
    </li>
    <li
      v-for="activeFilter in activeFilters"
      :key="activeFilter.optionId || activeFilter.id"
      class="flex rounded-md bg-gray-600 text-gray-200"
    >
      <div class="flex items-center p-2">
        <span v-if="activeFilter.name">{{ activeFilter.name }}</span>
        <span
          v-if="
            activeFilter.name &&
            (activeFilter.customValue || activeFilter.value)
          "
        >
          :&nbsp;
        </span>
        {{ activeFilter.customValue || activeFilter.value }}
      </div>
      <button
        class="cursor-pointer bg-transparent px-2"
        :aria-label="`Remove active filter: ${
          activeFilter.name || activeFilter.defaultName
        }`"
        :disabled="listingStore.isProcessing"
        @click="
          $emit(
            'remove',
            activeFilter.id,
            activeFilter.optionId ? activeFilter.value : null,
          )
        "
      >
        <IconClose />
      </button>
    </li>
  </ul>
</template>
