<script setup lang="ts">
type Platform = {
  name: string
  checked: boolean
}
const emit = defineEmits<{
  (e: 'change', options: string[]): void
}>()

const platforms = ref<Platform[]>([
  { name: 'Facebook', checked: false },
  { name: 'Instagram', checked: false },
  { name: 'TikTok', checked: false },
  { name: 'YouTube', checked: false },
  { name: 'Pinterest', checked: false },
  { name: 'Twitter', checked: false },
])
const listingStore = useListingStore()
const changeOptions = () => {
  const checkedPlatforms = platforms.value
    .filter((platform) => platform.checked)
    .map((platform) => platform.name)
  emit('change', checkedPlatforms)
}
const selectOptionsFromQuery = (query: string) => {
  const extraPlatforms = query?.split(',')
  platforms.value.forEach((platform) => {
    platform.checked = extraPlatforms
      ? extraPlatforms.includes(platform.name)
      : false
  })
}
const route = useRoute()
selectOptionsFromQuery(route.query.extra_platforms as string)
watch(
  () => route.query.extra_platforms,
  () => {
    selectOptionsFromQuery(route.query.extra_platforms as string)
  },
)
</script>

<template>
  <div class="mb-4 hidden items-center md:flex xl:mb-0">
    <div class="border-gray-400 pr-6 text-gray-200 xl:border-l xl:px-6">
      Filter by<br />
      Platform
    </div>
    <ul class="flex">
      <li
        v-for="(platform, index) in platforms"
        :key="platform.name"
        class="flex border border-r-0 transition-colors first:rounded-l-md last:rounded-r-md last:border-r"
        :class="[
          platform.checked ? 'border-blue-200 bg-blue-600' : 'border-gray-400',
          platforms[index - 1]?.checked ? 'border-l-blue-200' : '',
        ]"
      >
        <label
          class="flex items-center px-3 py-2"
          :class="
            listingStore.isProcessing
              ? 'cursor-progress opacity-75'
              : 'cursor-pointer'
          "
        >
          <input
            v-model="platform.checked"
            type="checkbox"
            class="sr-only"
            :disabled="listingStore.isProcessing"
            @change="changeOptions"
          />
          <Component
            :is="useIconComponent(`Icon${platform.name}`)"
            class="w-4 transition-colors"
            :class="platform.checked ? 'fill-blue-200' : 'fill-gray-200'"
          />
          <span class="pl-2">
            {{ platform.name }}
          </span>
        </label>
      </li>
    </ul>
  </div>
</template>
