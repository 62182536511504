<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { LocationQuery } from 'vue-router'
import type { ProductsRequestQuery } from 'gig/types/products-request-query'
import type { ProductGridItem } from 'gig/types/product-grid-item'
import sortMethods from '@/data/category/sort-methods'
import tags from '@/data/category/tags.json'
import IconMagnifierMinus from '@/assets/images/magnifier-minus.svg'
import type { BreadcrumbsItem } from '@/types/breadcrumbs-item'

const router = useRouter()
const route = useRoute()
const routeStore = useRouteStore()
const listingStore = useListingStore()
const authStore = useAuthStore()
const { isProcessing } = storeToRefs(listingStore)
const { isLoggedIn } = storeToRefs(authStore)
const { sendClick, sendImpressions } = useGigImpressions()
const breadcrumbHome = ref<BreadcrumbsItem>({ label: 'Home', to: '/' })
const breadcrumbItems = ref<BreadcrumbsItem[]>([
  {
    label:
      route.params?.query !== 'all'
        ? `Search results for: ${route.params.query}`
        : 'Search',
  },
])
const isFilterDialogVisible = ref(false)
const isFilterDialogEnabled = ref(false)
const {
  products,
  total,
  limit,
  offset,
  sortMethod,
  activeFilters,
  setParamsFromUrlQuery,
} = await useSearch({
  limit: 24,
  sortMethod:
    route.params.query !== 'all'
      ? sortMethods.find((method) => !method.default)?.code
      : undefined,
})

useHead({
  titleTemplate: '%s | Branded Content Search',
  title:
    route.params?.query !== 'all' ? `${route.params.query} Collabs` : 'Search',
  meta: [
    {
      name: 'description',
      content: route.params?.query
        ? `Collab search results for ${route.params.query} on Branded Content. `
        : 'Collab search results on Branded Content.',
    },
    {
      name: 'robots',
      content:
        activeFilters.value.length > 0 || !!route.query?.sort
          ? 'noindex, follow'
          : 'index, follow',
    },
  ],
})

const onGigGridItemIntersecting = (
  intersectionCount: number,
  product: ProductGridItem,
) => {
  if (intersectionCount === 1) {
    sendImpressions([product.id])
  }
  // Update page query when scrolling the page up and down
  if (route.query.page !== product.page) {
    router.push({ query: { ...route.query, page: product.page } })
  }
}

const favoritesStore = useFavoritesStore()
const { gigIds } = storeToRefs(favoritesStore)
const updateFavoriteGig = async (id: string) => {
  await favoritesStore.updateFavorite(id, 'gig')
}

const showFilterDialog = () => {
  isFilterDialogVisible.value = true
  isFilterDialogEnabled.value = true
}

const { getQueries, setActiveFiltersFromUrl, resetActiveFilters } =
  await useFilterProducts()
const setActiveFiltersValue = (queries: ProductsRequestQuery[] | null) => {
  offset.value = 0
  activeFilters.value = queries ? queries.filter(Boolean) : []
}
const changePlatformOptions = (checkedOptions: string[]) => {
  const queries = getQueries()
  const platformQuery = queries.find(
    (query) => query?.value.id === 'variants.attributes.extra_platforms',
  )
  if (checkedOptions.length === 0) {
    const nonPlatformQueries = queries.filter(
      (query) => query?.value.id !== 'variants.attributes.extra_platforms',
    )
    setActiveFiltersValue(nonPlatformQueries)
  } else if (platformQuery) {
    const updatedQueries = queries.map((query) => {
      if (query?.value.id === 'variants.attributes.extra_platforms') {
        return { ...query, value: { ...query.value, value: checkedOptions } }
      } else {
        return query
      }
    })
    setActiveFiltersValue(updatedQueries)
  } else {
    const newQuery = {
      name: 'filter',
      value: {
        id: 'variants.attributes.extra_platforms',
        value: checkedOptions,
      },
    }
    queries.push(newQuery)
    setActiveFiltersValue(queries)
  }
  setTimeout(() => {
    setActiveFiltersFromUrl()
  }, 0)
}
const clearFiltersOnSameRoutePath = (path: string) => {
  // If the route path remains the same /search/all and the user clicks on the See All button (from no results carousel)
  // we need to clear the active filters manually
  if (path === route.params?.query) {
    resetActiveFilters()
    activeFilters.value = []
  }
}
const removeActiveFilter = (id: string) => {
  activeFilters.value = activeFilters.value.filter(
    (filter) => filter.value.id !== id,
  )
}
const removeActiveFilterOption = (id: string, optionValue: string | null) => {
  const filter = activeFilters.value.find((filter) => filter.value.id === id)
  if (filter && filter.value?.value?.length > 1) {
    const updatedFilter = {
      ...filter,
      value: {
        ...filter.value,
        value: filter.value.value.filter((value) => value !== optionValue),
      },
    }
    activeFilters.value = activeFilters.value.map((filter) =>
      filter.value.id === id ? updatedFilter : filter,
    )
  } else {
    removeActiveFilter(id)
  }
}
const removeActiveFilterOrOption = (id: string, optionValue: string | null) => {
  if (optionValue) {
    removeActiveFilterOption(id, optionValue)
  } else {
    removeActiveFilter(id)
  }
  setTimeout(() => {
    setActiveFiltersFromUrl()
  }, 0)
}
const removeAllActiveFilters = () => {
  resetActiveFilters()
  activeFilters.value = []
}

const filteredSortMethods = computed(() =>
  sortMethods.filter((method) => method.default),
)

const firstLoadedPage = computed(() => products.value?.[0].page || '1')
const isLastPageLoaded = computed(
  () => Math.ceil(total.value / limit.value) === offset.value / limit.value + 1,
)
const loaderContainer = ref<HTMLElement | null>(null)
useIntersectionObserver(loaderContainer, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    offset.value = offset.value + limit.value
  }
})
const productsContainer = ref<HTMLElement | null>(null)
const isOnlyPageQueryUpdated = (): boolean => {
  const currentRouteQuery: LocationQuery = { page: '1', ...route.query }
  const previousRouteQuery: LocationQuery = {
    page: '1',
    ...routeStore.previousPageQuery,
  }
  if (
    Object.keys(currentRouteQuery).length !==
    Object.keys(previousRouteQuery).length
  ) {
    return false
  }
  for (const key of Object.keys(currentRouteQuery)) {
    if (key === 'page') {
      continue
    }
    if (currentRouteQuery[key] !== previousRouteQuery[key]) {
      return false
    }
  }
  return true
}
/*
After browser back/forward action, change scroll to the first product from the page specified in url query.
If products from the page aren't loaded, then reload the page.
Using event listener because currently it's not possible using vue router https://github.com/vuejs/vue-router/issues/3453
*/
const handlePopstate = async () => {
  if (isOnlyPageQueryUpdated()) {
    const firstProductOnCurrentPage =
      productsContainer.value?.children[
        products.value?.findIndex(
          (product) => product.page === route.query.page,
        )
      ]
    if (firstProductOnCurrentPage) {
      firstProductOnCurrentPage.scrollIntoView()
    } else {
      // Happens when you scroll down products, refresh the page and then click the back button
      location.reload()
    }
  } else {
    offset.value = 0
    activeFilters.value = []
    setParamsFromUrlQuery()
    await until(isProcessing).toBe(false)
    setActiveFiltersFromUrl()
  }
}

const logSearchAbandon = (redirectUrl = '') => {
  if (!redirectUrl.startsWith('/collab/') && isLoggedIn.value) {
    useKlaviyo('search_abandon', {
      query: route.params.query,
      redirectUrl,
    })
  }
}

setActiveFiltersFromUrl()
onMounted(() => {
  window.addEventListener('popstate', handlePopstate)
  window.addEventListener('beforeunload', () => {
    logSearchAbandon()
  })
})
onBeforeUnmount(() => window.removeEventListener('popstate', handlePopstate))
onBeforeRouteLeave(({ fullPath }) => logSearchAbandon(fullPath))
</script>

<template>
  <div>
    <Breadcrumb
      v-if="route.path !== '/search/all'"
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="mt-6 [&>ol]:flex [&>ol]:items-center"
    />

    <div class="flex flex-wrap justify-between">
      <div class="my-4 flex w-full justify-between md:w-auto">
        <Button
          id-cy="advanced-search-btn"
          label="Advanced Search"
          icon="pi pi-sliders-v"
          class="p-button-small mr-2 shrink-0 md:mr-6 lg:mb-0"
          icon-pos="left"
          aria-haspopup="dialog"
          :aria-expanded="isFilterDialogVisible"
          @click="showFilterDialog"
        />
        <LazyGigFilterDialog
          v-if="isFilterDialogEnabled"
          v-model:visible="isFilterDialogVisible"
          @update-visibility="isFilterDialogVisible = $event"
          @submit="(payload) => setActiveFiltersValue(payload)"
        />
        <Dropdown
          v-model="sortMethod"
          :options="
            route.params.query === 'all' ? filteredSortMethods : sortMethods
          "
          placeholder="Sort By"
          option-label="label"
          option-value="code"
          class="box-content h-9 shrink flex-row-reverse truncate"
          input-class="px-2 py-0 text-sm font-medium h-auto leading-9"
          dropdown-icon="pi pi-sort-amount-down"
          @change="offset = 0"
        />
      </div>

      <GigListingPlatformFilter @change="changePlatformOptions" />
    </div>

    <GigListingActiveFilters
      v-if="activeFilters?.length"
      :filters="activeFilters"
      @remove="removeActiveFilterOrOption"
      @remove-all="removeAllActiveFilters"
    />

    <div class="mb-4 text-gray-200">{{ total }} Collabs Available</div>

    <div class="divide-y divide-gray-400">
      <div
        v-if="products?.length"
        class="mb-4 md:mb-8"
      >
        <div
          v-if="firstLoadedPage !== '1'"
          class="mb-4 flex justify-center md:mb-8"
        >
          <Button
            class="p-button-outlined"
            label="Load more results"
            @click="offset = 0"
          />
        </div>
        <div
          ref="productsContainer"
          role="feed"
          :aria-busy="isProcessing"
          :aria-label="breadcrumbItems[0].label"
          class="grid gap-8 sm:grid-cols-2 sm:gap-4 md:grid-cols-3 lg:grid-cols-4"
        >
          <article
            v-for="(product, index) in products"
            :key="product.id"
            tabindex="0"
            :aria-posinset="(+firstLoadedPage - 1) * limit + index + 1"
            :aria-setsize="total"
            :aria-labelledby="`product-label-${product.id}`"
          >
            <GigGridItem
              class="h-full"
              :product="product"
              :is-gig-favorite="gigIds.includes(product.id)"
              :intersect-once="false"
              @click="sendClick($event.target, product.id)"
              @intersecting="
                (intersectionCount) =>
                  onGigGridItemIntersecting(intersectionCount, product)
              "
              @update-favorite-product="updateFavoriteGig"
            />
          </article>
        </div>
        <div
          v-if="!isLastPageLoaded"
          ref="loaderContainer"
          class="flex justify-center pt-4 md:pt-8"
          aria-hidden="true"
        >
          <div class="w-[42px]">
            <BaseLoader />
          </div>
        </div>
      </div>
      <template v-else>
        <div
          class="mb-8 flex flex-col items-center rounded-md border border-gray-400 bg-gray-600 py-10 text-center"
        >
          <IconMagnifierMinus class="mb-4" />
          <span class="text-base">No results found for your search</span>
        </div>
        <div class="py-9">
          <GigListingGigsCarousel
            @see-all-click="clearFiltersOnSameRoutePath"
          />
        </div>
      </template>
      <GigCategoryTags :items="tags" />
    </div>
  </div>
</template>

<style scoped>
:deep(.p-dropdown-trigger) {
  @apply w-7 border-0 bg-transparent text-gray-100;
}

:deep(.p-dropdown-trigger:hover) {
  @apply rounded-l;
}

:deep(.p-dropdown-label:hover) {
  @apply rounded-l;
}

:deep(.p-dropdown-label) {
  @apply !text-gray-100;
}

:deep(.p-dropdown-trigger-icon) {
  @apply pl-1 text-base;
}
</style>
