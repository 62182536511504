<script setup lang="ts">
const { products } = await useSearch({
  ignoreParamsFromUrl: true,
  sortMethod: [
    'variants.attributes.dspn-car desc',
    'variants.attributes.follower_count_numeric desc',
  ],
})
defineEmits<{
  (e: 'see-all-click', path: string): void
}>()
</script>

<template>
  <GigCarousel
    heading="Get Inspired by our Popular Collabs"
    :items="products"
  >
    <div class="mb-8 flex justify-between">
      <h2 class="text-2xl text-gray-100">
        Get Inspired by our Popular Collabs
      </h2>
      <BaseSeeAllLink
        url="/search/all"
        class="hidden md:block"
        aria-label="See all popular Collabs"
        @click="$emit('see-all-click', 'all')"
      />
    </div>
  </GigCarousel>
  <div class="mt-8 md:hidden">
    <BaseSeeAllLink
      url="/search/all"
      aria-label="See all popular Collabs"
      @click="$emit('see-all-click', 'all')"
    />
  </div>
</template>
