import { categoryFilters } from '@/data/category/category-filters'
import type { Products, Facet } from '@/types/products'

export default async function () {
  const filters = ref(categoryFilters)

  const getIdsOfFiltersToFetch = (): string[] => {
    return filters.value
      .filter(
        (item) =>
          !item.mapping && item.id !== 'variants.price.centAmount:range',
      )
      .map((item) => item.id)
  }

  const getQuery = (ids: string[]): string => {
    return ids.reduce((previousItem: string, currentItem: string) => {
      return previousItem + `&facet=` + currentItem
    }, '')
  }

  const setFilterOptions = (
    idsOfFiltersToFetch: string[],
    data: { [key: string]: Facet },
  ) => {
    idsOfFiltersToFetch.forEach((blockId) => {
      const facet = data[blockId]
      const filter = filters.value.find((item) => item.id === blockId)
      if (!filter) {
        return
      }
      if (filter.autocomplete) {
        filter.autocompleteOptions = facet?.terms.map((facet) => facet.term)
        return
      }
      filter.options = facet?.terms.map(({ term }: { term: string }) => ({
        id: term,
        type: 'checkbox',
      }))
    })

    filters.value.forEach((filter) => {
      if (filter.mapping) {
        filter.options = Array.isArray(filter.mapping)
          ? filter.mapping.map((groupName) => ({
              id: `${filter.id}-${groupName}`,
              groupName,
              groupOptions: [groupName],
              type: 'checkbox',
            }))
          : Object.entries(filter.mapping).map(([groupName, options]) => ({
              id: `${filter.id}-${groupName
                .toLowerCase()
                .split(' ')
                .join('-')}`,
              groupName,
              groupOptions: options,
              type: 'checkbox',
            }))
      }
    })
  }

  const idsOfFiltersToFetch = getIdsOfFiltersToFetch()
  const query = getQuery(idsOfFiltersToFetch)

  try {
    const { data, error } = await useApi<Products>(`opensearch?limit=0${query}`)

    if (error.value) {
      throw new Error(error.value?.message)
    }

    if (data.value) {
      setFilterOptions(idsOfFiltersToFetch, data.value.facets)
    }
  } catch (error) {
    console.error((error as Error).message)
  }

  return { filters }
}
