import type {
  CategoryFilter,
  CategoryFilterOption,
} from 'gig/types/category-filter'
import type { ProductsRequestQuery } from 'gig/types/products-request-query'

export default async function () {
  const { filters } = await useFetchFilters()

  const isOptionEnabled = (option: CategoryFilterOption) =>
    option.isEnabled || typeof option.isEnabled === 'undefined'

  const getActiveOptions = (
    options: CategoryFilterOption[],
  ): CategoryFilterOption[] =>
    options.filter((option) => isOptionEnabled(option) && !!option?.value)

  const getActiveFilters = (): CategoryFilter[] =>
    filters.value.filter((filter) => getActiveOptions(filter.options)?.length)

  const getOption = (
    options: CategoryFilterOption[],
    id: string,
  ): CategoryFilterOption | undefined => {
    return options.find((option) => option.id === id)
  }

  const getOptionIds = (options: CategoryFilterOption[]): string[] => {
    const optionIds: string[] = []
    options.forEach((option: CategoryFilterOption) => {
      if (Array.isArray(option.value)) {
        optionIds.push(...option.value)
      } else if (typeof option.value === 'string') {
        optionIds.push(option.value)
      } else if (Array.isArray(option.groupOptions)) {
        optionIds.push(
          ...option.groupOptions.filter((item) => !optionIds.includes(item)),
        )
      } else if (option.groupOptions && !isNaN(option.groupOptions)) {
        optionIds.push(`range(${option.groupOptions} to *)`)
      } else if (typeof option.value === 'object' && 'code' in option.value) {
        optionIds.push(option.value.code)
      } else {
        optionIds.push(option.id)
      }
    })
    // Reduce the list of range options to the one with the lowest bound
    if (optionIds.some((id) => id.includes('range'))) {
      const minLowerBound = Math.min(
        ...optionIds.map((id) => parseInt(id.match(/\d+/)?.[0] || '0')),
      )
      return optionIds.filter(
        (id) => parseInt(id.match(/\d+/)?.[0] || '0') === minLowerBound,
      )
    }
    return optionIds
  }

  const getPriceQuery = ({ id, options }: CategoryFilter) => {
    const minPriceCentAmount = getOption(options, 'min')?.value
    const maxPriceCentAmount = getOption(options, 'max')?.value

    if (
      typeof minPriceCentAmount === 'number' &&
      typeof maxPriceCentAmount === 'number' &&
      minPriceCentAmount > maxPriceCentAmount
    ) {
      const { $notify } = useNuxtApp()
      const errorText = "Min price can't be higher than max price"

      $notify({
        text: errorText,
        severity: 'error',
      })

      throw new Error(errorText)
    }

    const minPrice =
      typeof minPriceCentAmount === 'number' && minPriceCentAmount > 0
        ? minPriceCentAmount * 100
        : '*'
    const maxPrice =
      typeof maxPriceCentAmount === 'number' && maxPriceCentAmount > 0
        ? +maxPriceCentAmount * 100
        : '*'
    return {
      id,
      value: [`(${minPrice} to ${maxPrice})`],
    }
  }

  const getQuery = ({ id, options }: CategoryFilter) => {
    const chosenOptions = getActiveOptions(options)
    const optionIds = getOptionIds(chosenOptions)
    return {
      id,
      value: optionIds,
    }
  }

  const getQueries = (): ProductsRequestQuery[] => {
    const filters = getActiveFilters()
    const queries = filters
      .map((filter) => {
        if (filter.id === 'variants.price.centAmount:range') {
          return {
            name: 'filter',
            value: getPriceQuery(filter),
          }
        }
        return {
          name: 'filter',
          value: getQuery(filter),
        }
      })
      .reduce((acc: any[], filter) => {
        // Combine the same filters into one query (e.g. content_type)
        const existingFilter = acc.find((f) => f.value.id === filter.value.id)
        if (existingFilter) {
          existingFilter.value.value = Array.from(
            new Set([...existingFilter.value.value, ...filter.value.value]),
          )
        } else {
          acc.push(filter)
        }
        return acc
      }, [])
    const tagsFilter = {
      name: 'filter',
      value: {
        id: 'variants.attributes.tags',
        value: selectedTags.value,
      },
    }
    if (selectedTags.value?.length) {
      queries.push(tagsFilter)
    }
    return queries
  }

  const updateOptionState = (
    filterId: string,
    optionId: string,
    state: boolean,
  ) => {
    const options =
      filters.value.find((item: CategoryFilter) => item.id === filterId)
        ?.options || []
    const option = getOption(options, optionId)
    if (!option) {
      return
    }
    option.isEnabled = state
    option.value = state
  }

  const resetActiveFilters = () => {
    const activeFilters = getActiveFilters()
    activeFilters.forEach((filter) => {
      const chosenOptions = getActiveOptions(filter.options)
      chosenOptions.forEach((option: CategoryFilterOption): void => {
        switch (option.type) {
          case 'checkbox':
            option.value = false
            break
          case 'number':
            option.value = undefined
            break
          default:
            option.value = ''
        }
      })
    })
    selectedTags.value = []
  }

  const setActiveFiltersFromUrl = () => {
    resetActiveFilters()
    const route = useRoute()
    selectedTags.value = (route.query.tags as string)?.split(',') || []
    for (const [key, value] of Object.entries(route.query)) {
      const activeFilter = filters.value.find((fil) => fil.id.includes(key))

      if (!activeFilter) {
        continue
      }
      if (typeof value === 'string') {
        if (
          // Numeric range filters
          [
            'variants.price.centAmount:range',
            'variants.attributes.follower_count_numeric',
            'reviewRatingStatistics.averageRating',
          ].includes(activeFilter.id)
        ) {
          const range = value.replaceAll(/[()]/g, '').split(' to ')
          if (range.length === 2) {
            range.forEach((item, index) => {
              if (item !== '*' && !item.includes('range')) {
                activeFilter.options[index].value = +item / 100
              } else if (item.includes('range')) {
                const number = Number(item.match(/\d+/)?.[0])
                const option = activeFilter.options.find(
                  (el) =>
                    typeof el.groupOptions === 'number' &&
                    el.groupOptions === number,
                )
                if (option) {
                  option.value = true
                }
              }
            })
          }
        } else if (
          activeFilter.autocomplete &&
          activeFilter.id !== 'variants.attributes.tags'
        ) {
          let name = value
          if (activeFilter.id === 'variants.attributes.location') {
            name = getCountryName(name) || name
          }
          activeFilter.options[0].value = {
            name,
            code: value,
          }
        }
      }
      // Other filters
      const activeValues = value?.toString().split(',')
      activeFilter.options.forEach((item) => {
        const { id, groupName, groupOptions } = item
        if (
          activeValues?.includes(id) ||
          (groupName && activeValues?.includes(groupName)) ||
          (groupName &&
            Array.isArray(groupOptions) &&
            groupOptions.every((value) => activeValues?.includes(value)))
        ) {
          updateOptionState(activeFilter.id, id, true)
        }
      })
    }
  }

  const selectedTags = ref<string[]>([])
  const addTag = (searchItem: Ref<string | { name: string }>) => {
    if (typeof searchItem.value === 'object') {
      if (!selectedTags.value.includes(searchItem.value.name)) {
        selectedTags.value.push(searchItem.value.name)
      }
    }
    searchItem.value = ''
  }
  const removeTag = (tagToRemove: string) => {
    selectedTags.value = selectedTags.value?.filter(
      (tag) => tag !== tagToRemove,
    )
  }

  return {
    filters,
    getQueries,
    resetActiveFilters,
    setActiveFiltersFromUrl,
    selectedTags,
    addTag,
    removeTag,
  }
}
